import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppRoutes from './Routes';
import './App.css';
import CookiesServices from './services/CookiesService';
import Sidebar from './layout/sidebar/SideBar';
import Header from './layout/header/Header';
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from "react-ga4";

const App = () => {
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookieService = useMemo(() => new CookiesServices(), []);
    const navigate = useNavigate();
    const location = useLocation();
    const [isSecured, setIsSecured] = useState(true);

    const params = new URLSearchParams(window.location.search);
    var code = params.get('code');
    const isPasswordResetPage = ((code === 'passwordreset') || (location.pathname === '/passwordReset'));

    ReactGA.send({ hitType: "pageview", page: location.pathname });

    useEffect(() => {
        if (window.self === window.top) {
            setIsSecured(true); 
        } else {
            setIsSecured(false); 
        }

        const authenticateUser = async () => {
            // Attempt to save login details from URL
            const loginSuccess = await cookieService.saveLoginDetailsFromUrl();

            if (loginSuccess) {
                setIsLoggedIn(true);
                // navigate('/dashboard');
            } else {
                if (isPasswordResetPage) {
                    setIsLoggedIn(true);
                } else {
                    //Redirect to WSO2 for authentication if cookies are not valid
                    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
                }
            }
        };
        if (isPasswordResetPage) {
            setIsLoggedIn(true);
            navigate('/passwordReset');
        } else {
            authenticateUser();
        }
    }, [cookieService, navigate, isSecured, isPasswordResetPage]);

    if (!isSecured) {
        return <div className='security-msg'>If you see this page, Web App link you have clicked on is under Clickjacking security attack!</div>;
    }

    return (
        <div className="app">
            {!isLoggedIn ? (
                <div className="loading-overlay">
                <CircularProgress
                    size={60}
                    thickness={4.5}
                    sx={{
                        color: 'linear-gradient(to right, red, blue)',
                    }}
                />
            </div>
            ) : (
                <div className='content-wrapper'>
                    {!isPasswordResetPage && isSidebarVisible && <Sidebar />}
                    <div className={`main-content ${isSidebarVisible ? 'with-sidebar' : 'full-width'}`}>
                        {!isPasswordResetPage && <Header onToggleSidebar={() => setSidebarVisible(!isSidebarVisible)} currentPath={location.pathname} />}
                        <AppRoutes isLoggedIn={isLoggedIn} />
                        {/* {!isPasswordResetPage && <Footer />} */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;