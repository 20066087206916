import React, { useState, useEffect, useMemo } from 'react';
import { FaBell } from 'react-icons/fa';
import { IoReorderThreeOutline } from "react-icons/io5";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './Header.css';
import CookiesServices from '../../services/CookiesService';
import { FiSearch } from "react-icons/fi";
import SearchMenu from '../search-menu/SearchMenu';
import UserImg from '../../assets/user.jpg';
import '../../colors/colors.css';

const Header = ({ onToggleSidebar, currentPath }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [branch, setBranch] = useState('');
  const cookiesServices = useMemo(() => new CookiesServices(), []);
  const [openSearchMenu, setOpenSearchMenu] = useState(false);

  useEffect(() => {
    const storedCode = cookiesServices.get('code');
    const storedName = cookiesServices.get('name');
    const storedBranch = cookiesServices.get('branch');
    if (storedCode && storedName && storedBranch) {
      setCode(storedCode);
      setName(storedName);
      setBranch(storedBranch);
    }
  }, [cookiesServices]);

  const handleUserIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const accessToken = cookiesServices.get('AccessToken');
    cookiesServices.remove('AccessToken');
    cookiesServices.remove('UserName');
    cookiesServices.remove('Level');
    cookiesServices.remove('code');
    cookiesServices.remove('session_state');
    cookiesServices.remove('name');
    cookiesServices.remove('branch');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'AuthenticationToken': accessToken
        },
      });
    } catch (error) {
    }
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
  };

  const handleSearchMenu = () => {
    setOpenSearchMenu(!openSearchMenu);
  };

  const closeSearchMenu = () => {
    setOpenSearchMenu(false);
  };

  const formatPath = (path) => {
    if (!path) return '';
    const pathParts = path.replace(/^\/+/, '').split('/');
    return pathParts
      .map((part) =>
        part.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase())
      )
      .join(' ');
  };

  return (
    <div className="header">
      <div className="toggle-section">
        <IoReorderThreeOutline className="toggle" onClick={onToggleSidebar} />
        <div className="header-path">{formatPath(currentPath)}</div>
      </div>

      <div className="header-actions">
        <button className="header-button" onClick={handleSearchMenu}>
          <FiSearch className="header-icon" />
        </button>
        <FaBell className="header-icon" />
        <div className="user-icon" onClick={handleUserIconClick}>
          <img src={UserImg} alt="User" className="user-image" />
          <div className="user-info">
            <span className="code">{code || ''}</span>
            <span className="level">{branch || ''}</span>
            <span className="username">{name ? name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''}</span>
          </div>
        </div>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="menu"
      >
        <MenuItem className="menu-item">{`${code || ''}`}</MenuItem>
        <MenuItem className="menu-item">{`${branch || ''}`}</MenuItem>
        <MenuItem className="menu-item">{name ? name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''}</MenuItem>
      </Menu>

      {openSearchMenu && <SearchMenu onClose={closeSearchMenu} />}
    </div>
  );
};

export default Header;
